html {
    position: relative;
   height: 100%;
    scroll-behavior: smooth;
}
body{
    font-family: 'Sofia Pro', sans-serif;
    overflow-x: hidden;
    padding: 0px;
    margin: 0px;
    background-color: #ffff;
    overflow-x: hidden;
        font-size: 16px;
       color: #505B60;
            font-weight: 300;
}
a, a:hover{
    color: inherit;
    text-decoration: none;
}
.sec-pad{
    padding: 5rem 0;
}
.fw-500{
    font-weight: 500;
}
.btn-orange{
    background: transparent linear-gradient(180deg, #EEAB69 0%, #F98B1E 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 19px #F98B1E5E;
border-radius: 50px;
padding: 8px 18px;
color: #fff;
font-weight: 300;
min-width: 152px;
transition: all 0.2s ease;
border: 0px;
}
.btn-orange:hover{
    opacity: 0.8;
    color: #fff;
}
.btn-theme{
    background: #0AB4FF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 20px #0AB4FF33;
border-radius: 50px;
border-radius: 50px;
padding: 13px 25px;
color: #fff;
font-weight: 300;
min-width: 152px;
transition: all 0.2s ease;
border: 0px;
}
.btn-theme:hover{
    opacity: 0.8;
    color: #fff;
}
.sec-title{
    color: #0AB4FF;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
}
.sec-line{
    background: #F98B1E 0% 0% no-repeat padding-box;
border-radius: 3px;
width: 40px;
height: 5px;
margin-bottom: 40px !important;
}
.watch-overview{
    transition: all 0.2s ease;
}
.watch-overview:hover{
color: #F98B1E !important;
}

/* menu */
.sticky-bar {
  left: 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 5px 15px rgba(25, 25, 25, 0.1);
  background: #fff;
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #505b60;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    font-size: 17px;
        padding: 0px 20px !important;
}
.nav-link.active, .nav-link:hover{
color: #F98B1E ;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0rem;
}
#navbar-hamburger.show{
    display: block;
}
#navbar-hamburger, #navbar-close{
    display: none;
}
#navbar-close.show{
    display: block;
}
/* hero */
.hero{
    background-image: url(../img/hero.jpeg);
    background-position: top;
    background-size:cover;
    width: 100%;
    padding: 15rem 0rem;
}
.hero h1{
    color: #fff;
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
}
.hero p{
    text-align: center;
    color: #fff;
    font-size: 22px;
}
/* services */
.s-box{
    box-shadow: 0px 1px 20px #0000002B;
border: 1px solid #D9D9D9;
border-radius: 20px;
opacity: 1;
backdrop-filter: blur(30px);
-webkit-backdrop-filter: blur(30px);
padding: 42px 32px;
background-image: url(../img/ser-bg1.png);
    background-size: cover;
    height: 100%;
}
.s-bg-1{
   background-image: url(../img/ser-bg1.png);
    background-size: cover; 
}
.s-bg-2{
   background-image: url(../img/ser-bg2.png);
    background-size: cover; 
}
.s-bg-3{
   background-image: url(../img/ser-bg3.png);
    background-size: cover; 
}

/* pricing */
.p-box{
    box-shadow: 0px 1px 20px #0000001A;
border: 1px solid #D9D9D9;
border-radius: 20px;
padding: 30px 35px;
}
.pb-title{

color: #0AB4FF;
font-size: 35px;
font-weight: 600;
}
.p-box-mar{
    margin: 0rem 2rem;
}
.p-box.active{
   color: #fff;
    box-shadow: 0px 1px 20px #0000002b;
    border: 1px solid #D9D9D9;
    border-radius: 20px;
       padding: 54px 35px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../img/price-bg.png);
}
.p-box.active .pb-title{
color: #F2FBFF;
}
.p-box.active .btn-orange{
        padding: 12px 18px;
    min-width: 160px;
}
/* footer */
.primary-footer{
    background: #0AB4FF 0% 0% no-repeat padding-box;
    padding: 4rem 0;
    color: #fff;
}
.secondry-footer{
    background: #2C3C43 0% 0% no-repeat padding-box;
    color: #F2FBFF;
    text-align: center;
    padding: 10px;
    font-size: 14px;
}
.social .fa{
        font-size: 35px;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0px;
    transition: all 0.2s ease-in-out;
    margin-right: 10px;
    font-size: 35px;
}
.social .fa:hover, .footer-ql a:hover{
   opacity: 0.8;
}
.footer-ql{
       margin: 40px 6rem 0px;
}
.footer-ql  li{
    margin-bottom: 10px;
}
.footer-title{
    font-size: 18px;
    font-weight: 500;
}
.c-details-icon{
        width: 31px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.ml-5{
    margin-left: 5px;
}
.ml-9{
    margin-left: 9px;
}

/* about */
.hero.inner {
    padding: 5rem 0rem;
    min-height: 322px;
        display: flex;
    align-items: center;
}

.table-responsive-stack tr:hover{
    background-color: #D3E5F5 !important;
    cursor: pointer;
}
.table-responsive-stack tr:hover .edit-record{
   opacity: 1;
}
.table-responsive-stack tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
              background-color: #fff;
}
.table .thead-dark th {
    color: #fff;
    background-color: #214f88;
    border-color: #32383e00;
        font-weight: normal;
        background: transparent linear-gradient(180deg, #82D9FF 0%, #0AB4FF 100%) 0% 0% no-repeat padding-box;
}
.table .thead-dark tr th:first-child{
    border-top-left-radius: 20px;
}
.table .thead-dark tr th:last-child{
    border-top-right-radius: 20px;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(255 255 255);
}.table td, .table th {
       padding: 15px 15px;
    vertical-align: top;
    border-top: 0px solid #dee2e6;
}

.table-responsive-stack td,
.table-responsive-stack th {
   display:block;
/*      
   flex-grow | flex-shrink | flex-basis   */
   -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.table-responsive-stack .table-responsive-stack-thead {
   font-weight: bold;
}

@media screen and (max-width: 768px) {
   .table-responsive-stack tr {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      border-bottom: 3px solid #ccc;
      display:block;
      
   }
   /*  IE9 FIX   */
   .table-responsive-stack td {
      float: left\9;
      width:100%;
   }
}
.table{
        border: 1px solid #dee2e6;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          text-align: center;
          box-shadow: 0px 1px 20px #0000001A;
              overflow: hidden;

}
.bg-primary-box{
    background: #E5F7FF 0% 0% no-repeat padding-box;
    padding: 25px 35px;
    border-radius: 33px;
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.accordion-button:not(.collapsed) {
    color: #0ab4ff;
    background-color: #e7f1ff00;
    border-bottom: 0px solid #000;
    box-shadow: inset 0 -0px 0 rgb(0 0 0 / 13%);
}
.accordion-item:not(:first-of-type) {
        border: 1px solid rgba(0,0,0,.125);
}
.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 00rem rgb(13 110 253 / 25%);
}
.accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
     border-radius: 15px;
}
.accordion-item {
    border-top-left-radius: 50px;
    border-top-right-radius: 0.25rem;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 1px 20px #0000001a;
}

.accordion-button {
    padding: 1.3rem 1.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed, .accordion-item:last-of-type {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
      border-radius: 15px;
}
.contact-box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 20px #0000001A;
border: 1px solid #D9D9D9;
border-radius: 20px;
padding: 34px 15px 15px 15px;
text-align: center;
min-height: 205px;
transition: all 0.2s ease-in-out;
margin: 0px 2rem;
}
.contact-box:hover{
 background-color: #0ab4ff0a !important;
 cursor: pointer;
}
.op-7{
    opacity: 0.7;
}
.mb-26{
        margin-bottom: 26px !important;
}
.map{
    height: 400px;
}

/* login */
.login-box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #A3AEB2;
border-radius: 20px;
padding: 80px;
}
.ft-25{
    font-size: 25px;
}
.form-group label{
        color: #F98B1E;
    background-color: #fff;
    padding: 1px 11px;
    position: absolute;
    left: 16px;
    top: -13px;
    font-size: 15px;
}
.form-control{
    background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #D8D8D8;
border-radius: 8px;
padding: 17px 35px 17px 25px;
color: #000;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #fb9a1e;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}
.form-control::placeholder{

    color: #A3AEB2;
}
.form-group img{
        position: absolute;
    right: 13px;
    top: 20px;

}
.form-group{
    margin-bottom: 43px !important;
}
.br-8{
    border-radius: 8px;
}
.text-theme{
    color: #0AB4FF!important;
}
.ip-kc-box{
   background: #0AB4FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 20px #0000001a;
    border: 1px solid #D9D9D9;
    border-radius: 15px;
    font-size: 17px;
    color: #fff;
    padding: 11px 25px 25px 35px;
    width: fit-content;
}
 .ip-kc-box1{
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 20px #0000001A;
border: 1px solid #D9D9D9;
border-radius: 15px;
padding: 35px;
font-size: 17px;
    margin-top: -19px;
 }
/* how it work */
.htw-box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 20px #0000001A;
border: 1px solid #D9D9D9;
border-radius: 0px 25px 25px 0px;
border-left: 5px solid #F98B1E ;
padding: 20px 20px 40px 20px;
margin-top: 80px;
}
.htw-box-circle{
       width: 75px;
    height: 75px;
    background: #0AB4FF 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 40px;
    justify-content: center;
    align-content: center;
    display: flex;
    border-radius: 50px;
    padding-top: 6px;
    margin: auto;
}
.mtm-60{
        margin-top: -60px;
}
/* country-guide */
.country-guide-box{
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #A3AEB2;
border-radius: 20px;
padding: 60px 85px;

    text-align: left;
}

.container2 {
     display: inline-block;
    position: relative;
    padding-left: 35px;
   margin-bottom: 18px;
    cursor: pointer;
    color: #F98B1E;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 40px;
}

/* Hide the browser's default radio button */
.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container2 .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #eee0;
    border-radius: 50%;
    border: 2px solid #0ab4ff;
}

/* On mouse-over, add a grey background color */
.container2:hover input ~ .checkmark {
    background-color: #0ab4ff29;
}

/* When the radio button is checked, add a blue background */
.container2 input:checked ~ .checkmark {
  background-color: #2196f300;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container2 .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container2 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container2 .checkmark:after {
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #0ab4ff;
}
.form-floating>label {
    position: absolute;
    top: -5px;
    left: 10px;
    height: 23px;
    padding: 0px 10px;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
    color: #F98B1E;
    background-color: #fff;
    opacity: 1 !important;
    font-size: 18px;
}
.form-floating>.form-control, .form-floating>.form-select {
    height: calc(3.5rem + 2px);
    padding: 12px 10px 10px 22px !important;
    font-weight: normal !important;
}
.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
     font-weight: normal; 
    line-height: 1.5;
       color: #505b60;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.form-select:focus {
    border-color: #f9c58e;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}
.dropdown-menu{
    padding: 6px 0px;
    border: none;
    border-radius: 0px;
}
.dropdown-item {
    font-size: 17px;
    padding: 6px 20px;
    color: #505b60;
   font-weight: 300;
}
.dropdown-item.active, .dropdown-item:active {
    color: #F98B1E;
    text-decoration: none;
    background-color: transparent;
}
.dropdown-item:focus, .dropdown-item:hover {
   color: #F98B1E;
    background-color: transparent;
}
.dropdown-toggle::after {
    font-family: FontAwesome;
    content: "\f107"!important;
    border: none!important;
    vertical-align: initial;
}
@media all and (min-width: 1200px) {
    .navbar .dropdown-menu.fade-up{ top:180%; transition: all ease .3s; display:block; opacity: 0; visibility: hidden; }
    .dropdown:hover .dropdown-menu {
        display: block;
        opacity: 1;
        visibility: visible;
        top: 100%;
    }
    .dropdown:hover .dropend .dropdown-menu{
        display: none;
    }
    .dropend:hover .dropdown-menu {
        display: block!important;
        top: 0;
        right: auto;
        left: 100%;
        margin-top: 0;
    }
}


/* form step */

#form {
    text-align: center;
    position: relative;
    margin-top: 20px
}
  
#form fieldset {
    background: white;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative
}
  
.finish {
    text-align: center
}
  
#form fieldset:not(:first-of-type) {
    display: none
}
  
#form .form-btn1, .form-btn {
    width: 100px;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px 10px 0px;
    float: right
}
  
.form, .form-btn1 {
    background: #616161;
}
  
.form, .form-btn {
        background: #0ab4ff;
    border-radius: 8px;
    padding: 12px 44px;
    width: auto;
}
  
.form-btn1:hover,
 .form-btn1:focus {
    background-color: #0ab4ff
}
  
 .form-btn:hover,
 .form-btn:focus {
    background-color: #0ab4ff;
        opacity: 0.8;
}
  
.text {
    color: #2F8D46;
    font-weight: normal
}
  
#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    padding-left: 0px;
}
  
#progressbar .active {
    color: #ffffff
}
  
#progressbar li {
    list-style-type: none;
    font-size: 15px;
       width: 19%;
    float: left;
    position: relative;
    font-weight: 400;
    min-height: 96.5px;
    align-items: center;
}
  

#progressbar li:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
      border-top: 48px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 17px solid #d9e3f7;
    z-index: 2;
    transition: border-color 0.2s ease;
    right: auto;
    left: 0;
    border-left: 17px solid #fff;
    z-index: 0;
}
  #progressbar li.active{
    background-color: #0AB4FF; 
  }
  #progressbar li.active:after {
    border-left: 17px solid #0ab4ff;
}
#progressbar li:after {
       content: " ";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
   border-top: 48px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 17px solid #E8E8E8 ;
    z-index: 2;
    transition: border-color 0.2s ease;
}
#progressbar li:last-child:after {
   display: none;
}
  
/*#progressbar li.active:before,
#progressbar li.active:after {
    background: #2F8D46
}*/
 #progressbar.arrow-steps li:first-child:before {
    border: none;
}
.arrow-steps .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.arrow-steps .step:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.progress {
    height: 20px
}
  
.progress-bar {
    background-color: #2F8D46
}
.arrow-steps .step {
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    margin: 0 3px;
    padding: 10px 10px 10px 30px;
    min-width: 180px;
    float: left;
    position: relative;
    background-color: #E8E8E8 ;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
}
.ft-21{
        font-size: 21px;
}

.form, .form-btn1 {
    background: #858484 !important;
    background: #0ab4ff;
    border-radius: 8px !important;
    padding: 12px 44px !important;
    width: auto !important;
}
.form, .form-btn1:hover{
    opacity: 0.8;
}
.form-step-box p{
    font-size: 21px;
}

.input-fc{
    background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #D8D8D8;
border-radius: 5px;
padding: 5px 13px;
    font-size: 18px;
}
.input-fc::placeholder{
    color: #A3AEB2;
}
.w-150{
    width: 150px;
}
.w-95px{
    width: 95px;
}
.form-step-box .container2 {
    font-size: 18px;
}
.step .step-right{
    display: none;
}
.step.done .step-right{
    display: block;
}
.step.done .step-num{
    display: none;
}



/* The container */
.container1 {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
      color: #F98B1E;
 font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
      margin-right: 40px;
}

/* Hide the browser's default checkbox */
.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.container1 .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    border-radius: 3px;
    border: 1px solid #0ab4ff;
    background-color: #eee0;
}

/* On mouse-over, add a grey background color */
.container1:hover input ~ .checkmark {
 background-color: #0ab4ff29;
}

/* When the checkbox is checked, add a blue background */
.container1 input:checked ~ .checkmark {
        background-color: #2196f300;

}

/* Create the checkmark/indicator (hidden when not checked) */
.container1 .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container1 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container1 .checkmark:after {
     left: 11px;
    top: -5px;
    width: 7px;
    height: 17px;
    border: solid #2196f3;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.container1 input:checked ~ .checkmark:before {
    content: '';
    width: 11px;
    height: 13px;
    background-color: #fff;
    position: absolute;
    right: -4px;
    top: -2px;
}

.float-none{
    float: none;
}
.cl-scroll-modal{
    max-height: 73vh;
    overflow-x: auto;
    overflow-x: hidden;

}
.cl-scroll-modal::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.cl-scroll-modal::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.cl-scroll-modal::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.cl-scroll-modal::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
@media (min-width: 1440px){
.modal-xl {
    max-width: 1430px;
}
}
.form-floating1{
    position: relative;
}
.form-floating1>label {
    position: absolute;
    top: -15px;
    left: 10px;
    height: 23px;
    padding: 0px 10px;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
    color: #F98B1E;
    background-color: #fff;
    opacity: 1 !important;
    font-size: 18px;
}
.form-step-box .form-control {
    padding: 17px 25px 17px 25px;
}

.rb-header{
    background: #0AB4FF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 20px #0000000F;
padding: 15px;
text-align: center;
color: #fff;
font-size: 21px;
}
.result-li{
    padding: 15px;
    border-bottom: 1px solid #ccc6;
}
.result-li i{
    color: #a3aeb2;
}
/*.result-body .result-li:last-child{
     border-bottom: 0px solid #ccc6;
}*/

.result-table-box{
    border: 1px solid #D9D9D9;
    padding: 26px 22px 18px;
    margin-top: 30px;
}



.result-table-box table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  border: 1px solid #D9D9D9;
  overflow: visible;
}

.result-table-box table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.result-table-box table tr {
  border: 1px solid #ddd;
  padding: .35em;
}
.result-table-box table thead tr{
    background: #0AB4FF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 20px #0000000F;
border: 1px solid #D9D9D9;
}
.result-table-box table th,
.result-table-box table td {
      padding: 15px;
  text-align: center;
}

.result-table-box table th {
    color: #fff;
}

/*@media screen and (max-width: 600px) {
  .result-table-box table {
    border: 0;
  }

  .result-table-box table caption {
    font-size: 1.3em;
  }
  
  .result-table-box table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .result-table-box table tr {
    display: block;
    margin-bottom: .625em;
  }
  
  .result-table-box table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  .result-table-box table td::before {
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .result-table-box table td:last-child {
    border-bottom: 0;
  }
}*/
.result-table-box .nav-pills .nav-link.active, .result-table-box .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0ab4ff;
    padding: 23px 10px;
   
}
.result-table-box .nav-pills .nav-link{
     padding: 9px 25px !important;
}
#result{
    display: none;
}

.w-120{
       min-width: 102px;
}
thead th{
        border-bottom: 1px solid #dee2e6;
}
.min-140{
    min-height: 140px;
}
.sec-top-7{
        padding-top: 13rem;
    margin-top: -10rem;
}
.table>thead {
    vertical-align: bottom;
    background: transparent linear-gradient(180deg, #82D9FF 0%, #0AB4FF 100%) 0% 0% no-repeat padding-box;
    color: #fff;
}



/*account css*/

.font-23{
    font-size: 23px!important;
}
.font-25{
    font-size: 25px!important;
}
.table {
    border-radius: 20px;
}
.modal-lg{
    max-width: 700px;
}
.modal-content {
    border: 1px solid #A3AEB2;
    border-radius: 20px;
}
.border-box-padding {
    padding: 40px 80px;
}
.btn-close{
    position: absolute;
    right: 20px;
    top: 20px;
}
.btn-close:focus{
    outline: 0;
    box-shadow: none; 
}
.table.simple-table{
    border-radius: 0px;
    box-shadow: none;
    border: none;
}
.table td, .table th {
    border-color: #A3AEB2;
}
.table th{
    font-weight: 500;
}
.border-bottom-0{
    border-bottom-width: 0px!important;
}
.box-shadow-none{
    box-shadow: none!important;
}
.p-label{
    color: #505B60;
    font-size: 16px;
    font-weight: 300;
}
.medium{
    font-weight: 500!important;
}
.icon-img-width{
    display: inline-block;
    width: 20px;
    text-align: center;
}
.s-bg-4{
    background-image: url(../img/ser-bg4.png);
    background-size: cover;
}
.s-bg-none{
    background-image: none;
}
.video-listing p{
    display: inline-flex;
    align-content: flex-start;
    width: 31%;
    margin-right: 2%;
}
.user-list{
    border-bottom: 1px solid #D2D2D2;
    padding: 40px 0px 20px 40px;
}
.orange-text{
    color: #F98B1E!important;
}
.user-details-list li{
    display: inline-block;
    width: 31%;
    margin-right: 2%;
}
.account-info{
    background-color: #0AB4FF;
    padding: 40px 40px;
    border-radius: 20px;
    margin-bottom: 3rem;
}
.account-info p{
    color: #F2FBFF;
}
.account-info p .first-text{
    display: inline-block;
    width: 200px;
}
.account-info p:last-child{
    margin-bottom: 0px;
}