/*--------------------------------------------------------------
15. Media queries
--------------------------------------------------------------*/
@media only screen and (min-device-width: 1400px) and (max-device-width: 1600px){ 
   

}
@media only screen and (min-width : 1600px) {

}
@media only screen and (min-width: 1200px) {


}
@media only screen and (max-width: 1200px) {
    .video-listing p{
        width: 47%;
        margin-right: 2%;
    }
    .user-details-list li{
        width: 47%;
        margin-right: 2%;
    }
    .login-box {
        padding: 40px;
    }
    .border-box-padding {
        padding: 40px;
    }

}

@media only screen and (max-width: 980px) {
 .footer-ql{
       margin: 40px 0rem 0px;
}
.p-box-mar {
    margin: 0rem 0rem;
}
.p-box {
    padding: 30px 15px;
}
.p-box.active {
    padding: 38px 15px;
}
.nav-link {
    padding: 10px 20px !important;
}
.hero.inner {
    padding: 6rem 0rem;
}
.contact-box {
    margin: 0px 1rem;
}
.arrow-steps span{
    display: none;
}
#progressbar li {
    min-width: 39px;
    font-size: 15px;
    width: 19%;
    min-height: auto;
}

#progressbar li:after {
    border-top: 25px solid transparent;
    border-bottom: 28px solid transparent;
}
#progressbar li:before {
    border-top: 25px solid transparent;
    border-bottom: 28px solid transparent;
}
}
@media only screen and (max-width: 800px) {

}
@media only screen and (max-width: 768px) {
 .footer-ql{
    margin: 25px 0rem 0px;
}
.footer-title{
	 margin-top: 25px;
	}
.ml-9 {
    margin-left: 1px;
}.ml-5 {
    margin-left: 0px;
}
.p-box.active {
    margin: 0rem 2rem 2rem;
}
.p-box{
	margin: 2rem 2rem;
}
.sec-pad{
    padding: 3rem 0;
}
.s-box{
	margin-bottom: 30px;
	}
	.hero h1 {
    font-size: 30px;
}
.hero p {
    font-size: 19px;
}
.hero.inner {
    padding: 4rem 0rem;
    min-height: 254px;
}
.video-listing p{
    width: 100%;
    margin-right: 0%;
}
.user-details-list li{
    width: 100%;
    margin-right: 0%;
}
.user-list{
    border-bottom: 1px solid #D2D2D2;
    padding: 40px 0px 20px;
}
.login-box {
    padding: 20px;
}
.border-box-padding {
    padding: 20px;
}
}
@media (min-width: 992px){

}


@media only screen and (max-width: 640px) {
  .country-guide-box {
    padding: 14px 22px;
}
#progressbar li {
    width: 18%;
}
.result-li i{
  display: none;
}
.account-info p .first-text{
    display: inline-block;
    width: 100%;
}
}
    

@media only screen and (max-width: 590px) {

}
@media (min-width: 576px){

}

@media only screen and (max-width: 480px) {
  
}
@media only screen and (max-width: 400px) {

}
@media screen and (max-width: 375px){

}
@media screen and (max-width: 321px){

}







